<template>
	<a
		href="#"
		class="flex items-center flex-row text-lg leading-7 font-semibold uppercase font-nunito tracking-wide hover:no-underline focus:no-underline"
	>
		<img
			src="@/assets/img/clarus-logo.png"
			alt="Clarus Care"
			class="mr-1"
			:style="{ height, width }"
		/>
	</a>
</template>
<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Logo',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The logo image's height.
		 *
		 * @type {String}
		 */
		height: {
			type: String,
			default: '3.75rem',
		},

		/**
		 * The logo image's width.
		 *
		 * @type {String}
		 */
		width: {
			type: String,
			default: '9.75rem',
		},
	},
}
</script>

<template>
	<div class="flex flex-row flex-no-wrap w-full h-screen bg-white">
		<div class="auth-left">
			<logo class="mb-6 p-4" />

			<router-view class="auth-view my-auto p-4" />
			<div class="flex flex-col mt-auto">
				<p class="text-center text-gray-800 text-sm">
					Version {{ appVersion }}
				</p>
				<p class="text-center text-gray-800 text-sm">
					Clarus Care, LLC &copy; {{ new Date().getFullYear() }}.
				</p>
				<p class="text-center text-gray-700 text-sm">
					Made with
					<span class="text-lg text-red-500">&#x2665;</span> in
					Nashville
				</p>
			</div>
		</div>
		<div class="auth-right">
			<div class="auth-right-inner"></div>
		</div>
	</div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import { version } from '../../package.json'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Logo,
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Auth',

	data: () => ({
		appVersion: version,
	}),
}
</script>
